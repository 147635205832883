<template>
  <CFooter class="c-footer-dark" :fixed="false">
    <div>
      <a href="https://www.loonity.com" target="_blank">
        <img
          class="c-sidebar-brand-minimized"
          width="117"
          height="40"
          alt="Loonity Logo"
          src="./../../public/loonity/brand/LoonityLogoWhiteLarge.png"
        />
      </a>
    </div>
    <div class="mfs-auto">
      <hr class="d-sm-none" />
      <a href="https://www.addvalyou.ch" target="_blank">
        Addvalyou SA - 6835 Morbio Superiore - Switzerland
      </a>
      |
      <a href="/privacy.html" target="_blank"> Privacy </a>
      <br />
      <a href="https://www.loonity.com" target="_blank">
        Loonity - Addvalyou SA - ©
        {{ new Date().getFullYear() }} - 2020
      </a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: "TheFooter",
};
</script>
